import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PhoneLogin from './pages/login/PhoneLogin';
import DeleteAccount from './pages/delete-account/DeleteAccount';
import HomeRedirect from './pages/home/Home';
import './App.css';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<HomeRedirect />} />
          <Route path="/login" element={<PhoneLogin />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
        </Routes>
    </Router>
  );
};

export default App;