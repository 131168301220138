import React, { useState, useEffect } from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  UserCredential,
  PhoneAuthProvider,
  signInWithCredential
} from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import "./PhoneLogin.css";

const PhoneLogin: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [message, setMessage] = useState({ text: "", isError: false });
  const [showVerificationForm, setShowVerificationForm] = useState(false);

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
		auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
    );
    (window as any).recaptchaVerifier = recaptchaVerifier;
  }, []);

  const handleRequestCode = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const phoneProvider = new RecaptchaVerifier(
		auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
      );
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        phoneProvider
      );
      setVerificationId(confirmationResult.verificationId);
      setMessage({ text: "Verification code sent!", isError: false });
      setShowVerificationForm(true);
    } catch (error: any) {
      setMessage({ text: error.message, isError: true });
    }
  };

  const handleVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const userCredential: UserCredential = await signInWithCredential(auth, credential);
      setMessage({ text: "Login successful!", isError: false });
      console.log(userCredential.user);
    } catch (error: any) {
      setMessage({ text: error.message, isError: true });
    }
  };

  return (
    <div className="container">
      <header className="header">
	  <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 100">
                <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#6c63ff" stopOpacity={1} />
                        <stop offset="100%" stopColor="#4caf50" stopOpacity={1} />
                    </linearGradient>
                </defs>
                <path d="M40 70 L70 40 L100 70 M70 40 L70 10" stroke="url(#grad1)" stroke-width="12" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <text x="110" y="65" font-family="Arial, sans-serif" font-size="48" font-weight="bold" fill="#333">alfin</text>
                <polyline points="110,85 140,75 170,80 200,70 230,75 260,65 290,60" fill="none" stroke="#6C63FF" stroke-width="3" />
            </svg>
        <h1>Phone Login</h1>
      </header>
      <div className="loginForm">
        {!showVerificationForm ? (
          <form onSubmit={handleRequestCode}>
            <div className="formGroup">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="button">
              Request Verification Code
            </button>
          </form>
        ) : (
          <form onSubmit={handleVerify}>
            <div className="formGroup">
              <label htmlFor="verificationCode">
                Verification Code:
              </label>
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) =>
                  setVerificationCode(e.target.value)
                }
                required
              />
            </div>
            <button type="submit" className="button">
              Verify and Login
            </button>
          </form>
        )}
      </div>
      {message.text && (
        <div
          className={`message ${
            message.isError ? "error" : "success"
          }`}
        >
          {message.text}
        </div>
      )}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default PhoneLogin;