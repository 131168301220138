import React, { useState, useEffect } from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
  deleteUser
} from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import "./DeleteAccount.css";

const DeleteAccount: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [message, setMessage] = useState({ text: "", isError: false });
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
    );
    (window as any).recaptchaVerifier = recaptchaVerifier;
  }, []);

  const handleRequestCode = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const phoneProvider = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
      );
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        phoneProvider
      );
      setVerificationId(confirmationResult.verificationId);
      setMessage({ text: "Verification code sent!", isError: false });
      setShowVerificationForm(true);
    } catch (error: any) {
      setMessage({ text: error.message, isError: true });
    }
  };

  const handleVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      await signInWithCredential(auth, credential);
      setMessage({ text: "Phone number verified. Please confirm account deletion.", isError: false });
      setShowConfirmation(true);
    } catch (error: any) {
      setMessage({ text: error.message, isError: true });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await deleteUser(user);
        setMessage({ text: "Your account has been successfully deleted.", isError: false });
        setShowConfirmation(false);
      } else {
        throw new Error("No user is currently signed in.");
      }
    } catch (error: any) {
      setMessage({ text: error.message, isError: true });
    }
  };

  return (
    <div className="container">
      <div className="content">
        <header className="header">
          <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 100">
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#6c63ff" stopOpacity={1} />
                <stop offset="100%" stopColor="#4caf50" stopOpacity={1} />
              </linearGradient>
            </defs>
            <path d="M40 70 L70 40 L100 70 M70 40 L70 10" stroke="url(#grad1)" strokeWidth="12" fill="none" strokeLinecap="round" strokeLinejoin="round" />
            <text x="110" y="65" fontFamily="Arial, sans-serif" fontSize="48" fontWeight="bold" fill="#333">alfin</text>
            <polyline points="110,85 140,75 170,80 200,70 230,75 260,65 290,60" fill="none" stroke="#6C63FF" strokeWidth="3" />
          </svg>
          <h1>Delete Account</h1>
        </header>
        <div className="deleteForm">
          {!showVerificationForm ? (
            <form onSubmit={handleRequestCode}>
              <div className="formGroup">
                <label htmlFor="phoneNumber">Confirm your phone number:</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="button">
                Send Verification Code
              </button>
            </form>
          ) : showConfirmation ? (
            <div className="confirmationBox">
              <p>Are you sure you want to delete your account? This action cannot be undone.</p>
              <button onClick={handleDeleteAccount} className="button deleteButton">
                Yes, Delete My Account
              </button>
            </div>
          ) : (
            <form onSubmit={handleVerify}>
              <div className="formGroup">
                <label htmlFor="verificationCode">
                  Enter Verification Code:
                </label>
                <input
                  type="text"
                  id="verificationCode"
                  value={verificationCode}
                  onChange={(e) =>
                    setVerificationCode(e.target.value)
                  }
                  required
                />
              </div>
              <button type="submit" className="button">
                Verify
              </button>
            </form>
          )}
        </div>
        {message.text && (
          <div
            className={`message ${
              message.isError ? "error" : "success"
            }`}
          >
            {message.text}
          </div>
        )}
        <div id="recaptcha-container"></div>
      </div>
    </div>
  );
};

export default DeleteAccount;