import React, { useEffect } from 'react';

const HomeRedirect: React.FC = () => {
  useEffect(() => {
    window.location.href = '/home.html';
  }, []);

  return null;
};

export default HomeRedirect;